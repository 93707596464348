      <div id="intro-section" class="view"> 
        <video class="video-intro" style="height:900px" poster="https://clubb2b.com.br/images/main/background.jpg" 
          playsinline oncanplay="this.play()" onloadedmetadata="this.muted=true" loop>
          <source src="https://tecnopharmagroup.com.br/videos/pharma.mp4" type="video/mp4">
        </video>
        <div class="mask rgba-gradient d-md-flex justify-content-center align-items-center overflow-auto">
          <div class="container px-md-3 px-sm-0">
            <div class="row wow fadeIn">
              <div class="col-md-12 mb-4 white-text text-center mb-0 mt-5 pt-md-3 pt-3 wow fadeIn">
                <h1 *ngIf="innerWidth > 414" class="display-3 font-weight-bold sombra">
                  <a class="font-weight-bold teal-text">Tecnopharma</a>
                  <a class="font-weight-bold amber-text"> Group</a>
                </h1>
                <h1 *ngIf="innerWidth <= 414" class="display-5 font-weight-bold sombra">
                  <a class="font-weight-bold teal-text">Tecnopharma</a>
                  <a class="font-weight-bold amber-text"> Group</a>
                </h1>
                <h1 *ngIf="innerWidth > 414" class="display-3 font-weight-bold white-text sombra">
                  Sua StartUp de Negócios do Setor Farmacêutico
                </h1>
                <h1 *ngIf="innerWidth <= 414" class="display-5 font-weight-bold white-text sombra">
                  Sua StartUp de Negócios do Setor Farmacêutico
                </h1>
                <hr class="hr-light my-4 w-95">
                <h4 class="subtext-header text-justify mt-2 mb-4">
                  Somos uma empresa comprometida com a inovação tecnológica, desenvolvemos soluções que
                  aproximam, humanizam e integram empresas à empresas, empresas à pessoas, e todos à negócios. 
                  Nosso compromisso é maximizar e ampliar a geração de oportunidades de negócios 
                  <a class="font-weight-bold teal-text sombra">B</a>
                  <a class="font-weight-bold amber-text sombra">2</a>
                  <a class="font-weight-bold teal-text sombra">B</a> e 
                  <a class="font-weight-bold teal-text sombra">B</a>
                  <a class="font-weight-bold amber-text sombra">2</a>
                  <a class="font-weight-bold teal-text sombra">C</a> 
                  para toda a cadeia de negócios do setor farmacêutico.
                  Para nós, saúde e negócios são coisas muito sérias.
                  <p class="font-weight-bold text-center sombra pt-4">Em breve estaremos online para servi-los!</p>
                  <h1 *ngIf="innerWidth > 414" class="display-3 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra">
                    SITE EM CONSTRUÇÃO
                  </h1>
                  <h1 *ngIf="innerWidth <= 414" class="display-5 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra">
                    SITE/APP EM CONSTRUÇÃO
                  </h1>
                  <div *ngIf="innerWidth > 414" class="timer">
                    <h4 class="font-weight-bold red-text sombra"> Contagem Regressiva </h4>
                    <span id="days" class="font-weight-bold teal-text sombra"> {{daysToDday}} </span>
                    <a class="font-weight-bold sombra">Dia(s)</a>
                    <span id="hours" class="font-weight-bold teal-text sombra"> {{hoursToDday}} </span>
                    <a class="font-weight-bold sombra">Hs</a> 
                    <span id="minutes" class="font-weight-bold teal-text sombra"> {{minutesToDday}} </span>
                    <a class="font-weight-bold sombra">Min</a>
                    <span id="seconds" class="font-weight-bold teal-text sombra"> {{secondsToDday}} </span>
                    <a class="font-weight-bold sombra">Seg</a>
                  </div>
                  <div *ngIf="innerWidth <= 414" class="timer-mobile">
                    <h4 class="font-weight-bold text-center red-text sombra"> Contagem Regressiva </h4>
                    <span id="days" class="font-weight-bold teal-text sombra"> {{daysToDday}} </span>
                    <a class="font-weight-bold sombra">Dia(s)</a>
                    <span id="hours" class="font-weight-bold teal-text sombra"> {{hoursToDday}} </span>
                    <a class="font-weight-bold sombra">Hs</a> 
                    <span id="minutes" class="font-weight-bold teal-text sombra"> {{minutesToDday}} </span>
                    <a class="font-weight-bold sombra">Min</a>
                    <span id="seconds" class="font-weight-bold teal-text sombra"> {{secondsToDday}} </span>
                    <a class="font-weight-bold sombra">Seg</a>
                  </div>
                </h4> 
              </div>
            </div>
          </div>
        </div>
      </div>